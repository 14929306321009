import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, SvgIcon } from '@mui/material';
import { getQBAlerts } from 'api/adminHub';
import {
  deletePurchaseOrderItem,
  getAllPaymentSchedule,
  getAllProjects,
  postPurchaseOrderItems,
  updatePurchaseOrder,
  updatePurchaseOrderItems
} from 'api/index';
import type {
  GetPurchaseOrders,
  PaymentPoDetails,
  PaymentSchedule,
  PaymentSchedulesItems,
  Project,
  PurchaseOrderItem
} from 'api/types';
import Can from 'components/Can';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import CustomTextfield from 'components/NewLayout/Textfield';
import PaperContainer from 'components/PaperContainer';
import { QBBadge } from 'pages/AdminHub/Heading';
import QuickbooksAlert from 'pages/AdminHub/QuickbooksAlert';
import EditPoModal from 'pages/ProcurementWorkspace/components/EditPoModal';
import PaymentScheduleModal from 'pages/ProcurementWorkspace/components/PaymentScheduleModal';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAdminWorkspaceStore, useCommonStore } from 'store/index';
import {
  calculateItemsTotal,
  calculateModifiersTotal,
  getPrice,
  sortArrByKey,
  tabTitle
} from 'utils/index';
import PaymentScheduleTable from './component/PaymentScheduleTable';
import UpdatePaymentScheduleModal from './component/UpdatePaymentScheduleModal';

export default function PaymentWorkspace() {
  tabTitle('Payment Workspace');
  const { state } = useLocation();
  const [showCreatePayment, setShowCreatePayment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule[]>([]);
  const qbAlerts = useAdminWorkspaceStore((state) => state.qbAlerts);
  const setQBAlerts = useAdminWorkspaceStore((state) => state.setQBAlerts);
  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);
  const [openQBAlerts, setOpenQBAlerts] = useState<boolean>(false);
  const [purchaseOrderData, setPurchaseOrderData] = useState<PaymentPoDetails>();
  const [selectedPurchaseOrder, updateSelectedPurchaseOrder] = useState<GetPurchaseOrders | null>();
  const [isEditPoModalVisible, setEditPoModalVisibility] = useState<boolean>(false);
  const [showUpdatePaymentScheduleModal, setShowUpdatePaymentScheduleModal] =
    useState<boolean>(false);
  const [purchaseOrderItemsToBeDeletedList, setPurchaseOrderItemsToBeDeletedList] =
    useState<string[]>();
  const [allProjects, setAllProjects] = useState<DropdownOptionType[]>([]);

  const [newRows, setNewRows] = useState<PurchaseOrderItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [paginationData, setPaginationData] = useState<{
    pageNumber: number;
    pageSize: number;
    searchText: string;
  }>({ pageNumber: 1, pageSize: 10, searchText: '' });

  useEffect(() => {
    setComponentHeading('Payment Workspace');
    getQBAlerts().then((res) => setQBAlerts(res ?? []));
    fetchProjects();
    fetchAllPayments();
  }, [paginationData, state?.purchase_order_number]);

  const fetchAllPayments = async () => {
    setIsLoading(true);
    try {
      const { pageNumber, pageSize, searchText } = paginationData;

      const filterValue = searchText?.trim() ? searchText : state?.purchase_order_number;

      const getPaymentSchedule = await getAllPaymentSchedule(pageNumber, pageSize, filterValue);

      const filteredPayments =
        searchText?.trim() || !state?.purchase_order_id
          ? getPaymentSchedule.data
          : getPaymentSchedule.data.filter(
              (res: PaymentSchedulesItems) => res.purchase_order_id === state.purchase_order_id
            );

      const allPayments = filteredPayments.map((p) => ({
        ...p,
        subRows: p.paymentschedules
      }));

      setPaymentSchedule(allPayments);
      setTotalCount(getPaymentSchedule.totalCount);
    } catch (error) {
      console.error('Error fetching payments:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = () => {
    getAllProjects()
      .then((projects) => {
        const sortedProjects: Project[] = sortArrByKey(
          projects
            .filter((p) => p.appearance_order_and_logistic)
            .map((p) => ({ ...p, project_code: parseInt(p.project_code) })),
          'project_code'
        );

        setAllProjects(
          sortedProjects.map((item) => ({
            id: item.project_id ?? '',
            value: `${item.project_code} - ${item.project_name}`
          }))
        );
      })
      .catch((e) => {
        console.error(`Error: ${e.name} - ${e.message}`);
      });
  };

  const onClosePaymentScheduleModal = () => {
    fetchAllPayments();
    setNewRows([]);
    updateSelectedPurchaseOrder(null);
    setShowUpdatePaymentScheduleModal(false);
  };

  const onCompletePaymentScheduleModal = async () => {
    setIsLoading(true);
    selectedPurchaseOrder && (await updatePurchaseOrder_());
    await fetchAllPayments();
    setIsLoading(false);
    setShowUpdatePaymentScheduleModal(false);
  };

  const handlePurchaseOrderNumberClick = (row: GetPurchaseOrders) => {
    updateSelectedPurchaseOrder(row);
    setPurchaseOrderItemsToBeDeletedList([]);
    setEditPoModalVisibility(true);
  };

  const billAlerts = qbAlerts?.filter((a) =>
    ['CREATE_BILL', 'UPDATE_BILL'].includes(a.action_type)
  );

  const deletePurchaseOrderItem_ = async (purchaseOrderItemId: string) => {
    setPurchaseOrderItemsToBeDeletedList((prev = []) => [...prev, purchaseOrderItemId]);

    if (selectedPurchaseOrder) {
      const updatedPurchaseOrder = {
        ...selectedPurchaseOrder,
        purchase_order_items: selectedPurchaseOrder?.purchase_order_items?.filter(
          (item) => item.purchase_order_item_id !== purchaseOrderItemId
        )
      };

      updateSelectedPurchaseOrder(updatedPurchaseOrder);
    }
  };

  const paymentPoDetails = () => {
    const newRowsTotal = calculateItemsTotal(newRows);
    const existingPurchaseOrderItemsTotal = calculateItemsTotal(
      selectedPurchaseOrder?.purchase_order_items
    );
    const total = Number(newRowsTotal) + Number(existingPurchaseOrderItemsTotal);

    const { shipping_cost, estimated_taxes, discount, deposit, refund } = selectedPurchaseOrder;

    const poCalcualtedTotal: number =
      total +
      parseFloat(shipping_cost || '0') +
      parseFloat(estimated_taxes ?? '0') -
      parseFloat(discount ?? '0') -
      parseFloat(deposit ?? '0') -
      parseFloat(refund ?? '0');

    const purchaseOrderData: PaymentPoDetails = {
      total_amount: poCalcualtedTotal,
      paid_amount: getPrice(selectedPurchaseOrder, 'total'),
      remaining_amount: poCalcualtedTotal - getPrice(selectedPurchaseOrder, 'total'),
      scheduled_amount: getPrice(selectedPurchaseOrder),
      po_number: selectedPurchaseOrder?.purchase_order_number || '',
      vendor_name: selectedPurchaseOrder?.vendor?.vendor_name || '',
      po_id: selectedPurchaseOrder?.purchase_order_id || ''
    };
    return purchaseOrderData;
  };

  const updatePurchaseOrder_ = async () => {
    const prevPaymentScheduleState = [...paymentSchedule];
    try {
      setIsLoading(true);
      if (selectedPurchaseOrder) {
        const getPaymentSchedule = await getAllPaymentSchedule(
          1,
          1,
          selectedPurchaseOrder?.purchase_order_number
        );

        if (newRows.length) {
          const newPurchaseOrderItems_: Partial<PurchaseOrderItem>[] = newRows.map((item) => ({
            item_name: item.item_name,
            quantity: item.quantity,
            unit_of_measure: item.unit_of_measure,
            price: item.price,
            urgent_order_status_id: 2,
            project_id: item.project_id,
            description: item.description,
            suggested_vendor: '',
            purchase_order_id: selectedPurchaseOrder?.purchase_order_id
          }));

          await postPurchaseOrderItems(newPurchaseOrderItems_);
        }

        const existingItemsTotal = selectedPurchaseOrder?.purchase_order_items
          ? calculateItemsTotal(selectedPurchaseOrder.purchase_order_items)
          : 0;

        const newItemsTotal = newRows.reduce(
          (total, item) => total + Number(item.price) * Number(item.quantity),
          0
        );

        const modifierDetails = {
          shipping_cost: selectedPurchaseOrder?.shipping_cost,
          estimated_taxes: selectedPurchaseOrder?.estimated_taxes,
          discount: selectedPurchaseOrder?.discount,
          deposit: selectedPurchaseOrder?.deposit,
          refund: selectedPurchaseOrder?.refund
        };

        const modifiersTotal = calculateModifiersTotal(modifierDetails);
        const total_price = (
          Number(existingItemsTotal) +
          Number(modifiersTotal) +
          Number(newItemsTotal)
        ).toFixed(2);

        const purchaseOrderToUpdate: GetPurchaseOrders = {
          ...selectedPurchaseOrder,
          paymentschedules: getPaymentSchedule.data[0].paymentschedules,
          total_price
        };

        purchaseOrderItemsToBeDeletedList?.length &&
          (await deletePurchaseOrderItem(purchaseOrderItemsToBeDeletedList));

        selectedPurchaseOrder?.purchase_order_items &&
          (await updatePurchaseOrderItems(selectedPurchaseOrder.purchase_order_items));

        await updatePurchaseOrder(purchaseOrderToUpdate, selectedPurchaseOrder.purchase_order_id);
      }
    } catch (error) {
      console.error('Error adding purchase order item:', error);
      setPaymentSchedule(prevPaymentScheduleState);
    } finally {
      setIsLoading(false);
    }
    setEditPoModalVisibility(false);
  };

  const onClose = () => {
    setNewRows([]);
    updateSelectedPurchaseOrder(null);
  };

  return (
    <PaperContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CustomTextfield
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon component={Search} />
                </InputAdornment>
              )
            }}
            name="search"
            labelText="Search"
            placeholder="Search something"
            onChange={(e) =>
              setPaginationData({
                ...paginationData,
                searchText: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ textAlign: 'end', marginBottom: '10px' }}>
          {billAlerts?.length > 0 && (
            <Can I="read" module="QuickBooksAlert">
              <QBBadge count={billAlerts.length} onClickQBAlerts={() => setOpenQBAlerts(true)} />
            </Can>
          )}
          {state?.purchase_order_id && paymentSchedule.length == 0 && (
            <div style={{ float: 'right', marginRight: '10px' }}>
              <CustomButton
                onClick={() => setShowCreatePayment(true)}
                className="admin-workspace-btn admin-workspace-create-btn"
                label={`Create Payment`}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <br />
      <PaymentScheduleTable
        loading={isLoading}
        paymentSchedule={paymentSchedule}
        fetchAllPayments={fetchAllPayments}
        totalCount={totalCount}
        setPaginationData={setPaginationData}
        onClickPurchaseOrderNumber={handlePurchaseOrderNumberClick}
      />
      {showCreatePayment && (
        <PaymentScheduleModal
          purchaseOrderId={state?.purchase_order_id}
          selectedPoTotalPrice={
            state?.subRows?.reduce((sum, item) => sum + Number(item.price), 0) ?? 0
          }
          selectedVendorPayment={''}
          poModifiers={{
            estimated_taxes: state?.estimated_taxes,
            shipping_cost: state?.shipping_cost,
            discount: state?.discount,
            deposit: state?.deposit,
            refund: state?.refund
          }}
          onClose={() => setShowCreatePayment(false)}
          onComplete={() => fetchAllPayments()}
        />
      )}

      {openQBAlerts && (
        <QuickbooksAlert
          open={openQBAlerts}
          setOpen={() => setOpenQBAlerts(false)}
          openSection={2}
        />
      )}

      {isEditPoModalVisible && selectedPurchaseOrder && (
        <EditPoModal
          isOpen={isEditPoModalVisible}
          deletePurchaseOrderItem={deletePurchaseOrderItem_}
          purchaseOrder={selectedPurchaseOrder}
          updateSelectedPurchaseOrder={updateSelectedPurchaseOrder}
          setPOModalVisibility={setEditPoModalVisibility}
          onClose={onClose}
          allProjects={allProjects}
          setShowUpdatePaymentScheduleModal={setShowUpdatePaymentScheduleModal}
          newRows={newRows}
          setNewRows={setNewRows}
        />
      )}

      {showUpdatePaymentScheduleModal && selectedPurchaseOrder && (
        <UpdatePaymentScheduleModal
          paymentPoDetails={paymentPoDetails()}
          purchaseOrder={selectedPurchaseOrder}
          onClose={() => onClosePaymentScheduleModal()}
          onComplete={() => onCompletePaymentScheduleModal()}
        />
      )}
    </PaperContainer>
  );
}
